<template>
  <div id="app">
    <vue-ins-progress-bar />

    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>
<script>
import { core } from '@/config/pluginInit'

const LayoutFull = () => import('@/layouts/Layout2.vue')
const LayoutAuth = () => import('@/layouts/AuthLayout.vue')
const LayoutRegister = () => import('@/layouts/RegisterLayout.vue')
const LayoutNotFound = () => import('@/layouts/NotFoundLayout.vue')

export default {
  name: 'App',

  components: {
    LayoutFull,
    LayoutAuth,
    LayoutRegister,
    LayoutNotFound
  },

  metaInfo () {
    const appName = process.env.VUE_APP_TITLE
    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    }
  },

  computed: {
    layout () {
      if (this.$route.meta.layout === 'auth') return 'layout-auth'
      if (this.$route.meta.layout === 'register') return 'layout-register'
      if (this.$route.meta.layout === '404') return 'layout-not-found'

      return 'layout-full'
    }
  },

  mounted () {
    this.$insProgress.finish()
    core.mainIndex()
  }
}
</script>

<style lang="scss">
  @import "assets/scss/style.scss";
</style>
