import sharedRoutes from './shared-routes'
import adminRoutes from './admin-routes'
import userRoutes from './user-routes'
import sraRoutes from './sra-routes'
import ccoRoutes from './cco-routes'
import warehouseRoutes from './warehouse-routes'
import logisticsRoutes from './logistics-routes'
import tmRoutes from './tm-routes.js'
import managerRoutes from './manager-routes.js'

const allRoutes = []

export default allRoutes.concat(
  sharedRoutes,
  adminRoutes,
  userRoutes,
  sraRoutes,
  ccoRoutes,
  warehouseRoutes,
  logisticsRoutes,
  tmRoutes,
  managerRoutes
)
