import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'

// Request interceptor
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["request"] }] */
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()
  request.headers['ngrok-skip-browser-warning'] = 'ngrok-skip-browser-warning'

  return request
})

// Response interceptor
axios.interceptors.response.use(response => Promise.resolve(response), error => {
  if (error.response !== undefined) {
    const { status } = error.response

    if (status >= 500) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      })
    }

    if (status === 400) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error.response.data.message,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      })
    }

    if (status === 429) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Server is busy!',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      })
    }

    if (status === 403) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Oppss! Your are attempting to access restricted page.',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      }).then(() => {
        if (store.getters['auth/check']) {
          store.dispatch('auth/logout')
        }

        router.replace({ name: 'shared.login' })
      })
    }

    if (status === 404) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      })
    }

    if (status === 405) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      })
    }

    if (status === 401) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Your session expired.',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      }).then(() => {
        if (store.getters['auth/check']) {
          store.dispatch('auth/logout')
        }

        router.replace({ name: 'shared.login' })
      })
    }

    return Promise.reject(error)
  } else {
    if (error.message === 'Network Error') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Server is unreachable. Please contact IT Department.',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Dismiss'
      })
    }
  }

  return Promise.reject(error)
})
