function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/sales-order',
    component: view('SalesOrder'),
    name: 'user.sales-order',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Sales Order',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/sales-order-tracker/:status?',
    component: view('SalesOrderTracker'),
    name: 'user.sales-order-tracker',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Sales Order Tracker',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
