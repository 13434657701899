function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const adminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenance/users',
    component: view('Users'),
    name: 'admin.maintenance.users',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenance/warehouses',
    component: view('Warehouses'),
    name: 'admin.maintenance.warehouses',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Warehouses',
          active: true
        }
      ]
    }

  },
  {
    path: '/admin/maintenance/distributions',
    component: view('Distributions'),
    name: 'admin.maintenance.distributions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Distributions',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenance/sale-people',
    component: view('SalePeople'),
    name: 'admin.maintenance.sale-people',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Sale People',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenance/customers',
    component: view('Customers'),
    name: 'admin.maintenance.customers',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Customers',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenance/product-categories',
    component: view('Categories'),
    name: 'admin.maintenance.product-categories',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Product Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenance/products',
    component: view('Products'),
    name: 'admin.maintenance.products',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Products',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenance/discounts',
    component: view('Discounts'),
    name: 'admin.maintenance.discounts',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Discounts',
          active: true
        }
      ]
    }
  }
]

export default adminRoutes
