import ApiService from '@/services/core/api.service'

const SharedSalesOrderService = {
  async get (query) {
    return ApiService.get(`shared/sales-order?${query}`)
  },

  async post (payload) {
    return ApiService.post('shared/sales-order', payload)
  },

  async put (payload) {
    return ApiService.put(`shared/sales-order/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`shared/sales-order/${payload.id}`)
  },

  async getPendingSalesOrder (payload) {
    return ApiService.get(`shared/sales-order?${payload}`)
  },

  async updateCloseSo (payload) {
    return ApiService.put('shared/update-close-so', payload)
  }
}

export default SharedSalesOrderService
