import ApiService from '@/services/core/api.service'

const ListService = {

  async getDistributionList () {
    return ApiService.get('shared/distributions')
  },

  async getCustomerList (distributionId) {
    return ApiService.get(`shared/customers?distribution_id=${distributionId}`)
  },

  async getTermList () {
    return ApiService.get('shared/payment-terms')
  },

  async getDiscountList () {
    return ApiService.get('shared/discounts')
  },

  async getProductCategoryList () {
    return ApiService.get('shared/product-categories')
  },

  async getProductList (categoryId) {
    return ApiService.get(`shared/products?category_id=${categoryId}`)
  },

  async getSalePersonList () {
    return ApiService.get('shared/get-sale-people')
  }
}

export default ListService
