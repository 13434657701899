import ApiService from '@/services/core/api.service'

const AdminUserDistributionService = {
  async get (query) {
    return ApiService.get(`admin/fetch-user-distribution?user_id=${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/assign-user-distribution', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/users/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`admin/users/${payload.id}`)
  }
}

export default AdminUserDistributionService
